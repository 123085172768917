import React from "react";

export default function NotFound(props) {
    return (
        <div className="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
            <div className="max-w-screen-lg mx-auto">
                <main className="sm:flex">
                    <p className="text-4xl font-extrabold text-ie-light-purple sm:text-5xl">
                        404
                    </p>
                    <div className="sm:ml-6">
                        <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                            <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                Not Found
                            </h1>
                            <p className="mt-1 text-base text-gray-500">
                                The 404 or Not Found error message is a HTTP
                                standard response code indicating that the
                                client was able to communicate with the server,
                                but the server could not find what was
                                requested.
                            </p>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}
